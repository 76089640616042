import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/password-input/security",
  "title": "PasswordInput - Security"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "security",
      "style": {
        "position": "relative"
      }
    }}>{`Security`}<a parentName="h2" {...{
        "href": "#security",
        "aria-label": "security permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`As HDS password input provides only the front-end solution, remember that you need to take care of the security in your project.
Here are some good practices you need to consider.`}</p>
    <h3 {...{
      "id": "general-security",
      "style": {
        "position": "relative"
      }
    }}>{`General Security`}<a parentName="h3" {...{
        "href": "#general-security",
        "aria-label": "general security permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Do not prevent the user from copying and pasting the password into the field.`}</li>
      <li parentName="ul">{`Prefer using ready authentication libraries and services, and familiarize yourself properly with their implementation.`}</li>
      <li parentName="ul">{`In City of Helsinki Services, always use Helsinki Profiili authentication if possible.`}</li>
      <li parentName="ul">{`Never store the password in a readable form. Always use proper hash algorithms.`}</li>
      <li parentName="ul">{`Always protect the communication with secure connections (TLS 1.2 or newer).`}</li>
      <li parentName="ul">{`Encourage usage of multi-factor authentication and password managers.`}</li>
    </ul>
    <h3 {...{
      "id": "creating-a-new-password",
      "style": {
        "position": "relative"
      }
    }}>{`Creating a new password`}<a parentName="h3" {...{
        "href": "#creating-a-new-password",
        "aria-label": "creating a new password permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Inform user properly what the requirements for their password are.`}</li>
      <li parentName="ul">{`Do not accept password matching user identifier, e.g. a username or an email.`}</li>
      <li parentName="ul">{`Do not truncate, sanitize, or format in any way the password user has given.`}</li>
      <li parentName="ul">{`Set minimum length for password 12 to 16 characters.`}</li>
      <li parentName="ul">{`Avoid setting a maximum password length smaller than 128 characters.`}</li>
      <li parentName="ul">{`If possible, allow the password input to reflect the strength of the password entered when the password component is used for creating anew password.`}</li>
      <li parentName="ul">{`If possible, add other programmatic assistance. Detect and suggest changes to provide the user with assistance in generating strong passwords.`}</li>
    </ul>
    <h3 {...{
      "id": "using-password-to-log-in",
      "style": {
        "position": "relative"
      }
    }}>{`Using password to log in`}<a parentName="h3" {...{
        "href": "#using-password-to-log-in",
        "aria-label": "using password to log in permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`In an error message, you should not declare whether the username or the password is wrong but give general instructions to fix the problem.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      